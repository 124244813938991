var filialWrap = jQuery(".filialen_grid");
var filialListe = jQuery( "#filialen_list" );


jQuery( "#filialen_list" ).append('<div class="post-999 col-12 p-3 col-md-12 col-lg-8 mx-auto mx-lg-0 mb-3 button_filial article-container"><b><a style="color:white;" href="/hoeren/hoermobil/">Hörmobil</a></b></div>');

jQuery( "#filialen_list" ).find(".article-container").each(function(index) {
    jQuery(this).on("click", function(){
        var classFilial = jQuery(this).attr('class').slice(0,8);
        // alert(classFilial);
        filialWrap.find("div").hide();
        filialWrap.find("#"+classFilial).show();
        jQuery(this).parent().addClass("mobileList_disable");
        filialListe.addClass("d-none");
        jQuery("#filterBTN_mobile").removeClass("opened");

    });
});

jQuery("#alle_filialen").on("click", function(){
    filialWrap.find("div").fadeIn();

});

jQuery("#filterBTN_mobile").on("click", function(){
    filialListe.toggleClass("d-none");
    filialListe.removeClass("mobileList_disable");
    jQuery(this).toggleClass("opened");
});

jQuery("#accordion .card").each(function(index) {
    jQuery(this).on("click", function(){
        jQuery(this).siblings().find(".card-header").removeClass("opened");
        jQuery(this).find(".card-header").toggleClass("opened");
        
    });
});
